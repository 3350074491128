//スライダー
//---------------------------------------------------------
const homeHeroSwiper = new Swiper('.js-hero-slide', {
	slidesPerView: 1,
	loop: true,
	speed: 2000,
	autoplay: {
		disableOnInteraction: false,
		delay: 5000
	},
	effect: 'fade',
	fadeEffect: {
		crossFade: true,
	},
	pagination: {
		el: '.swiper-pagination',
		type: 'bullets',
		clickable: true
	}
});
